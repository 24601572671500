<template>
  <div class="wrapper">
    <div class="heading">
      <div class="matchday">
        <p>Season 286305</p>
        <span>Matchday 16</span>
      </div>
      <div class="time">
        <p>Live</p>
        <span class="live">01:32</span>
      </div>
    </div>
    <div class="games-list">
      <div class="game">
        <div class="team">
          <div class="home">
            <p>0</p>
            <p>Valencia</p>
            <div class="score">
              <div>
                <p>2</p>
              </div>
              <div>
                <p>2</p>
              </div>
            </div>
          </div>
          <div class="away">
            <p>0</p>
            <p>Valencia</p>
          </div>
        </div>
        <div class="score-line">
          <div>
            <p>h1</p>
          </div>
          <div>
            <p>0</p>
            <p>0</p>
          </div>
        </div>
      </div>
      <div class="game">
        <div class="team">
          <div class="home">
            <p>0</p>
            <p>Valencia</p>
            <div class="score">
              <div>
                <p>2</p>
              </div>
              <div>
                <p>2</p>
              </div>
            </div>
          </div>
          <div class="away">
            <p>0</p>
            <p>Valencia</p>
          </div>
        </div>
        <div class="score-line">
          <div>
            <p>h1</p>
          </div>
          <div>
            <p>0</p>
            <p>0</p>
          </div>
        </div>
      </div>
      <div class="game">
        <div class="team">
          <div class="home">
            <p>0</p>
            <p>Valencia</p>
            <div class="score">
              <div>
                <p>2</p>
              </div>
              <div>
                <p>2</p>
              </div>
            </div>
          </div>
          <div class="away">
            <p>0</p>
            <p>Valencia</p>
          </div>
        </div>
        <div class="score-line">
          <div>
            <p>h1</p>
          </div>
          <div>
            <p>0</p>
            <p>0</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ongoing',
  components: {},
};
</script>

<style scoped src="./index.css"></style>
